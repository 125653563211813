import React from 'react';

import axios from "axios";
import {API_BASE_URL, HEADER_TOKEN} from "../../configs/AppConfig";
import {notification } from "antd";
import {AUTH_TOKEN} from "../../redux/constants/Auth";
import history from "../../history";


export const ApiService = axios.create({
  baseURL: API_BASE_URL,
  timeout: 600000
});


ApiService.interceptors.request.use(config => {
  const jwtToken = localStorage.getItem(AUTH_TOKEN);
  if (jwtToken) {
    config.headers[HEADER_TOKEN] = jwtToken;
  }
  return config
}, error => {
  // Do something with request error here
  notification.error({message: 'Error' });
  Promise.reject(error)
});

export const handlerError = (error) => {
  let notificationParam = { message: 'Ocurrió un error', description: 'Algo no ha funcionado correctamente, revise la operación' };
  let location = window.location

  // Remove token and redirect
  if (error.response?.status === 401) {
    notificationParam.message = 'Authentication Fail';
    notificationParam.description = 'Please login again';
    if(!location.pathname.includes('auth'))
      localStorage.setItem('redirect', location.pathname+location.search);
    localStorage.removeItem(AUTH_TOKEN);
    history.push("/auth/login");
    //Retraso la carga para que aparezca el mensaje de fallo
    setTimeout(() => location.reload(), 1000);
  }

  if (error.response?.status === 300) {
    notificationParam.message = error.response.data.titulo
    notificationParam.description = error.response.data.descripcion

  }
  if (error.response?.status === 404) {
    notificationParam.message = 'Not Found'
  }

  if (error.response?.status === 500) {
    notificationParam.message = 'Internal Server Error'
  }

  if (error.response?.status === 508) {
    notificationParam.message = 'Time Out'
  }

  notification.error(notificationParam);

  return Promise.reject(error);
};
