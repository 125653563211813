import { 
  DashboardOutlined,
  ExclamationCircleOutlined,
  CheckCircleOutlined,
  AlertOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

const dashBoardNavTree = [
//   {
//   key: 'task',
//   path: `${APP_PREFIX_PATH}/list`,
//   title: 'Tareas',
//   icon: DashboardOutlined,
//   breadcrumb: false,
//   submenu: []
// },
//   {
//     key: '2',
//     path: `https://plataforma.innovacionsalud.org/app/peticiones/180/dashboard/CTRL_LIMP_I`,
//     title: 'Incidencias',
//     icon: ExclamationCircleOutlined,
//     external: true,
//     breadcrumb: false,
//     submenu: []
//   },
//   {
//     key: '3',
//     path: `https://plataforma.innovacionsalud.org/app/peticiones/180/dashboard/CTRL_LIMP_C`,
//     title: 'Completadas',
//     icon: CheckCircleOutlined,
//     external: true,
//     breadcrumb: false,
//     submenu: []
//   },
//   {
//     key: '4',
//     path: `https://plataforma.innovacionsalud.org/app/peticiones/180/dashboard/CTRL_LIMP_P`,
//     title: 'Prioritarias',
//     icon: AlertOutlined,
//     external: true,
//     breadcrumb: false,
//     submenu: []
//   }
  ]

const navigationConfig = [
  ...dashBoardNavTree
]

export default navigationConfig;
