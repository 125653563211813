import {ApiService, handlerError} from "../ApiService";
import {scanData, responseExportation} from "./transform/ScanTransform";


const ScanService = {};


ScanService.scan = (data) => {
  return ApiService.post('', scanData(data))
    .then((res) => console.log(res))
    .catch(handlerError);
}



export default ScanService