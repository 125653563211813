import React, { Component } from 'react';
import { QrcodeOutlined } from '@ant-design/icons';
import {Menu, Modal, message, Button, Alert} from 'antd';
import { connect } from "react-redux";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import ScanService from "../../services/api/scan";
import Utils from "../../utils";

export class NavPanel extends Component {
	state = { visible: false };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
	};
	
	render() {

      const modalStyle = {
        content: {
          height: "100vh",
          backgroundColor: "green",
          color: "white",
          textAlign: "center",
          paddingTop: "10%",
        },
        title: {
          color: "white",
        },
        subtitle: {
          color: "white",
        },
      };

		return (
      <>
        <Menu mode="horizontal">
          <Menu.Item onClick={this.showDrawer}>
            <Button type="primary" shape="circle">
              <div className="justify-content-center">
                <QrcodeOutlined className="nav-icon mr-0" />
              </div>
            </Button>
          </Menu.Item>
        </Menu>
        { this.state.visible ? <Modal
          visible={this.state.visible}
          footer={null}
          onCancel={this.onClose}

        >
          <BarcodeScannerComponent

              onUpdate={(err, result) => {
                if (result) {
                  // const modal = Modal.success({
                  //   title: 'Tarea completada',
                  //   content: <p dangerouslySetInnerHTML={{__html: '<h1>TEST</h1>'}}></p>,
                  //
                  // });
                  // setTimeout(() => {
                  //   modal.destroy();
                  // }, 5000);
                  ScanService.scan(Utils.getCode(result.text)).then(
                      () => {
                        message.success("Código Aceptado")
                      }
                  )
                  this.onClose()
                }
              }}
          />
        </Modal> : null}

        {/*<Modal*/}
        {/*  visible={true}*/}
        {/*  footer={null}>*/}
        {/*  <Alert*/}
        {/*      message="Success Tips"*/}
        {/*      description="Detailed description and advice about successful copywriting."*/}
        {/*      type="success"*/}
        {/*      showIcon*/}
        {/*  />*/}
        {/*</Modal>*/}

      </>
    );
	}
}

const mapStateToProps = ({ theme }) => {
  const { locale } =  theme;
  return { locale }
};

export default connect(mapStateToProps)(NavPanel);